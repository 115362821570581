@import "~antd/dist/antd.css";
@import "~react-lazy-load-image-component/src/effects/blur.css";

@import "./variables.scss";

.top-header-banner {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #1cbcbc;
  z-index: 1;

  @include breakpoint(xs) {
    padding: 4px 16px;
  }

  .ant-page-header-heading {
    justify-content: center;
  }
  .ant-page-header-heading-title {
    color: #fff;
    font-size: 16px;
    line-height: 28px;
    overflow: visible;
    white-space: initial;
    text-overflow: initial;

    @include breakpoint(xs) {
      font-size: 14px;
      line-height: 24px;
    }

    a,
    i {
      margin-left: 1px;
      margin-right: 1px;
      color: #fff;
      text-decoration: underline;
      font-style: italic;
    }
  }
}

.responsive-table {
  overflow-x: auto;
}

.required-input-field {
  color: #ff0000;
  margin-top: 6px;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  // max-width: 1024px;
  margin: auto;

  @media (min-width: 768px) and (max-width: 1200px) {
    padding: 0 15px;
  }

  &.main-container--modern-design {
    max-width: 1600px;
    padding: 0 15px;

    .navbar-logo-container {
      flex-direction: row;
      align-items: center;
      gap: 0 10px;

      @include breakpoint(xl) {
        flex-direction: column;
      }
    }

    .side-bar {
      padding: 0 20px;
    }

    @include breakpoint(lg) {
      &,
      .side-bar {
        padding: 0;
      }
    }
  }

  &.main-container--full-layout {
    max-width: 100%;
    padding: 0;

    .tour-detail {
      &,
      &__body {
        padding: 16px;

        @include breakpoint(xl) {
          padding: 0 20px;
        }

        @media only screen and (max-width: 767px) {
          padding: 0;
        }
      }
    }
  }

  &.main-container--full-search-layout {
    background-color: #f6f7fb;
    .navbar {
      width: 100%;
      background-color: #fff;
    }
  }

  &.main-container--community-design,
  &.main-container--full-background-layout {
    max-width: 100%;

    &.main-container {
      .ant-layout-content {
        background-color: #fff;

        @include breakpoint(lg) {
          background-color: #fff;
        }
      }

      .main_layout--content {
        background-color: #fff;
        padding-top: 0;

        .heading-container {
          padding-top: 24px;
        }
      }

      .side-header-device {
        padding-bottom: 24px;
      }

      &.main-container--my-page-layout {
        .ant-layout-content {
          background-color: #f6f7fb;

          @include breakpoint(lg) {
            background-color: #f6f7fb;
          }
        }
      }
    }

    .navbar-container,
    .guide-meetup-content-container,
    .world-rambler-content-container {
      max-width: 1600px;
      margin: 0 auto;
      padding-left: 50px;
      padding-right: 50px;

      @include breakpoint(xxl) {
        padding-left: 35px;
        padding-right: 35px;
      }

      @include breakpoint(lg) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  &.main-container--community-design {
    .navbar-logo-container {
      flex-direction: row;
      align-items: center;
      gap: 0 10px;

      @include breakpoint(xl) {
        flex-direction: column;
      }
    }

    .navbar-container {
      margin-bottom: 0;
    }
  }

  &.main-container--trip-layout {
    .navbar-logo-container {
      flex-direction: row;
      align-items: center;
      gap: 0 10px;

      @include breakpoint(xl) {
        flex-direction: column;
      }
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      padding: 0;
    }
  }

  .breadcrumb {
    display: flex;
    align-items: center;
    max-width: 1600px;
    list-style: none;
    margin: 0;
    padding: 0 50px;

    li {
      font-family: "Poppins";
      font-size: 11px;
      color: rgba(24, 23, 62, 0.5);

      &.separator {
        margin: 0 6px;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .page-title {
    max-width: 1600px;
    padding: 0 50px;
    font-family: "Poppins";
    font-size: 18px;
    color: #18173e;
    margin-bottom: 15px;

    @media screen and (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .page-footer {
    background: #fff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.07);
    padding: 12px 15px;

    .inner-content {
      display: flex;
      flex-wrap: wrap;
      max-width: 1024px;
      margin: auto;
      grid-gap: 10px;
      gap: 10px;
    }

    .left-content,
    .right-content {
      display: flex;
      grid-gap: 10px;
      gap: 10px;
    }

    .right-content {
      margin-left: auto;
    }

    @media screen and (max-width: 576px) {
      .ant-btn-lg {
        height: 24px;
        padding: 0px 12px;
        font-size: 14px;
        border-radius: 24px;
      }
    }
  }
}

.footer-container {
  &.footer-container--full-background-layout,
  &.footer-container--community-design {
    footer {
      margin-top: 0;
    }
  }
}

table {
  tr {
    background-color: #fff;
  }
}

span,
div,
a,
a.ant-typography,
.ant-typography a {
  &.link-primary {
    cursor: pointer;
    color: #1cbcbc;

    &:hover,
    &:focus {
      color: #15a0a0;
    }
  }

  &.link-secondary {
    cursor: pointer;
    color: #000;

    &:hover,
    &:focus {
      color: #000;
    }
  }
}

.ant-btn-primary {
  background: #1cbcbc;
  border-color: #1cbcbc;

  &:hover,
  &:focus {
    background: #15a0a0;
    border-color: #15a0a0;
  }

  &:disabled {
    background: #ccc;
    border-color: #ccc;
    color: #fff;
  }

  &.ant-btn-background-ghost {
    border-color: #1cbcbc;
    color: #1cbcbc;

    &:hover {
      border-color: #15a0a0;
      color: #15a0a0;
    }
  }
}

input.single-border-input {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #707070;
}

.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
  width: 21px;
  height: 21px;
  padding: 2px;
  border: 1px solid #1f1e39;
}

.ant-steps-dot .ant-steps-item-tail {
  top: 6px;
}

.ant-steps-item-wait
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: transparent;
}

.ant-steps-vertical.ant-steps-dot
  .ant-steps-item-process
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon-dot {
  left: 0;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #cccccc;
}

.ant-steps-dot .ant-steps-item-tail::after {
  width: calc(100% - 15px);
  margin-left: 16px;
}

.ant-menu-submenu-popup {
  display: none;
}

.ant-menu-sub.ant-menu-inline {
  .ant-menu-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .ant-menu-item-group-title {
    padding-left: 35px;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.credit-card-info {
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  border: 1px solid #15a0a0;
  border-radius: 5px;
  padding: 15px;

  label {
    display: block;
    margin-bottom: 5px;
  }

  span {
    display: block;
  }

  .card-number span {
    font-size: 18px;
    font-weight: 600;
  }
}

.terms-of-use-title {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: bold;
}

.iframe-container {
  margin: 0 0 20px;
  border: 1px solid #c4c4c4;
  overflow-y: scroll;

  iframe {
    margin-bottom: 0; // fallback
    margin-bottom: -6px;
  }
}

.jst-time-content {
  color: #ff8800;
  font-weight: 500;
}

.daylight-note {
  color: #ff8800;
  font-style: italic;
  font-weight: 500;
}

.process-information-container {
  position: relative;
  padding: 16px;

  .spinner-container {
    padding-top: 40px;

    @include breakpoint(md) {
      display: none;
    }
  }
}

.search-input-container {
  width: 275px;

  @include breakpoint(lg) {
    width: 100%;
  }
}

.other-search-inputs-container {
  display: flex;
}

.date-picker-container {
  margin-right: 10px;
}

.date-range-picker-container {
  .ant-picker-input {
    > input {
      @include breakpoint(md) {
        width: 100%;
      }
    }
  }

  .ant-picker-range-separator {
    padding-left: 0;

    @include breakpoint(md) {
      padding-left: 8px;
    }
  }
}

.time-picker-container {
  .ant-picker-input {
    > input {
      width: 70px;

      @include breakpoint(md) {
        width: 100%;
      }
    }

    .ant-picker-range-separator {
      padding-left: 0;

      @include breakpoint(md) {
        padding-left: 8px;
      }
    }
  }
}

.checked-items-checkbox-container {
  .show-itineraries-checkbox,
  .popular-checkbox {
    margin-right: 5px;
  }
}

/* Helper styles */
.cursor-pointer {
  cursor: pointer;
}

.overflow-x-auto {
  overflow-x: auto;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-none-important {
  display: none !important;
}

.display-block-important {
  display: block !important;
}

.width-fit-content {
  width: auto; // fallback
  width: fit-content;
}

.travel-category-label {
  height: 24px;
  padding: 0 12px;
  border-radius: 24px;
  background-color: #a29f9f;
  color: #fff;
  text-align: center;
  font-size: 12px;
  line-height: 2;
  margin-right: 2px;
  margin-bottom: 2px;

  &.is-1 {
    background-color: #44d692;
  }

  &.is-2 {
    background-color: #ebb2a8;
  }

  &.is-3 {
    background-color: #98d6e4;
  }

  &.is-4 {
    background-color: #fcac48;
  }

  &.is-5 {
    background-color: #b89bfe;
  }

  &.is-6 {
    background-color: #2ca2ba;
  }
}

.notice-description {
  max-height: 400px;
  line-height: 1;
  overflow-y: hidden;
  white-space: pre-line;
  margin: 0;
  p {
    margin: 0;
    white-space: pre-line;
  }
}

.custom-intro-tooltip {
  width: 80vw !important;
  max-width: 400px !important;

  .introjs-skipbutton {
    display: none;
  }
}

.intro-flashing {
  position: absolute;
  border: 2px solid orange;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
}

/* Google Maps Styling overrides */
.gm-style {
  .marker-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__name {
      margin-bottom: 8px;
      font-weight: bold;
    }

    p {
      margin-bottom: 8px;
    }

    &__action {
      text-decoration: underline;
    }
  }
}

.otp-input-container {
  > div {
    margin: 16px 8px;

    @include breakpoint(md) {
      margin-left: 6px;
      margin-right: 6px;
    }

    @include breakpoint(xs) {
      margin-left: 3px;
      margin-right: 3px;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    .otp-input {
      width: 100% !important;
      height: 50px;
      font-size: 22px;
      border-radius: 4px !important;
      background-color: #fafbfd !important;
      border: 1px dotted #ccc !important;
      color: #000;

      @include breakpoint(md) {
        height: 40px;
        font-size: 18px;
      }

      @include breakpoint(xs) {
        width: 100% !important;
        height: 32px;
        font-size: 14px;
      }

      @media (max-width: 250px) {
        width: 32px !important;
        font-size: 12px;
        border-radius: 0;
      }
    }
  }

  @include breakpoint(xs) {
    overflow: scroll;
  }
}

.full-screen-mobile-modal-container {
  @include breakpoint(lg) {
    .ant-modal {
      width: 100vw !important;
      background-color: #fff;
      max-width: unset;
      margin: unset;
    }

    .ant-modal-content {
      box-shadow: none;
      border-radius: 0;
    }

    .ant-modal-centered::before {
      content: unset;
    }

    .ant-modal-body {
      min-height: 100vh;
    }
  }
}

.ant-modal-content {
  border-radius: 8px;
  overflow: hidden;
}

.ant-picker-date-range-wrapper {
  border-radius: 8px;
  overflow: hidden;
}

.ant-popover-inner {
  border-radius: 8px;
}

.ant-dropdown-menu {
  border-radius: 8px;
}

.ant-rate {
  color: #ffcc66;
}

@supports (-webkit-touch-callout: none) {
  .hide-on-ios {
    display: none;
  }
}

.pc-only { display: block; }
.sp-only { display: none; }
@include breakpoint(xs) {
  .pc-only { display: none; }
  .sp-only { display: block; }
}