html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: break-spaces;
}

#root {
  height: 100%;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
  background: white !important;

  @media screen and (max-height: 920px) {
    height: unset !important;
  }
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.no-border-btn {
  border: 0 !important;
  box-shadow: none !important;
}

.mt-1 {
  margin-top: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.h-100 {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-carousel {
  .slick-prev {
    left: 10px !important;
    z-index: 10;
    width: 30px !important;
    height: 30px !important;

    svg {
      width: 30px;
      height: 30px;
      font-size: inherit;
      color: white;
    }
  }

  .slick-next {
    right: 10px !important;
    z-index: 10;
    width: 30px !important;
    height: 30px !important;

    svg {
      width: 30px;
      height: 30px;
      font-size: inherit;
      color: white;
    }
  }
}
